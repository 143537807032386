import React, { useEffect, useState } from "react";
import axios from "axios";
import "./HomePage.css";
import moment from "moment";

const HomePage = () => {
  const [fuelData, setFuelData] = useState({
    petrolstock: 10.29,
    petroldensity: 753.10,
    petrolrate: 100.69,
    dieselstock: 57.54,
    dieseldensity: 825.40,
    dieselrate: 90.23,
    manager: "Ms. Kavita Yadav",
    operator: " Mr. Shridhar Mhatre",
    managerconatct: "9372419087",
    operatorcontact: "9920188726",
    pesono: "P/WC/MH/14/5431(P152695)",
    gstnoreliancebp: "27AAHCR2546N2ZV",
    gstvalidity: "31.12.2031",
    gstnorbml: "27AAKCR8762R1Z7",
    updated_date:"",
    dieseldensity_ust2: 700.0,
    petroldensity_ust2: 700.0,
  });

  useEffect(() => {
    const fetchFuelData = async () => {
      try {
        const response = await axios.get("fuel-data");
        setFuelData(response.data);
      } catch (error) {
        console.error("Error fetching fuel data:", error);
      }
    };

    fetchFuelData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="container">
  <div className="header" style={{ position: 'relative', height: '15%' }}>
    <div
      style={{
        backgroundColor: '#0B983A',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '75%',
        zIndex: 2,
      }}
    ></div>
    <div
      style={{
        backgroundColor: '#F1D91F',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
      }}
    ></div>
    <img
      style={{
        position: 'absolute',
        top: '50%',
        left: '76%',
        transform: 'translate(-50%, -50%)',
        zIndex: 3,
      }}
      src="jiologo.png"
      alt="Jio BP Logo"
    />
  </div>

  <table className="pricing-table">
    <thead>
      <tr>
      <th style={{textAlign: 'left'}}>
  <span>Date - {moment().format('DD-MM-YYYY')}</span>
 </th>
        <th
          style={{
            color: '#f4f4f4',
            textAlign: 'center',
            backgroundColor: '#0B983A',
            borderWidth: 10,
            borderColor: '#ffffff',
            margin: 10,
          }}
        >
          Petrol
        </th>
        <th
          style={{
            color: '#f4f4f4',
            textAlign: 'center',
            backgroundColor: '#004AAD',
            borderWidth: 10,
            borderColor: '#ffffff',
            margin: 10,
          }}
        >
          Diesel
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style={{ fontSize: '2rem', fontWeight: 'bold', textAlign: 'left'}}>Stock<br /> 
        <span style={{fontWeight: 'normal' }}>(KL)</span>
          </td>
        <td
          style={{
            backgroundColor: '#000',
            borderWidth: 10,
            borderColor: '#ffffff',
            margin: 10,
          }}
        >
          <span className="highlight" id="petrolstock">
            {fuelData.petrolstock}
          </span>
        </td>
        <td
          style={{
            backgroundColor: '#000',
            borderWidth: 10,
            borderColor: '#ffffff',
          }}
        >
          <span className="highlight" id="dieselstock">
            {fuelData.dieselstock}
          </span>
        </td>
      </tr>
      <tr>
        <td style={{ fontSize: '2rem' , fontWeight: 'bold', textAlign: 'left'}}>
        Density - UST 1 <br />
        <span style={{fontWeight: 'normal' }}> (Kg/m<sup>3</sup>)</span>
        </td>
        <td
          style={{
            backgroundColor: '#000',
            borderWidth: 10,
            borderColor: '#ffffff',
          }}
        >
          <span className="highlight" id="petroldensity">
            {fuelData.petroldensity}
          </span>
        </td>
        <td
          style={{
            backgroundColor: '#000',
            borderWidth: 10,
            borderColor: '#ffffff',
          }}
        >
          <span className="highlight" id="dieseldensity">
            {fuelData.dieseldensity}
          </span>
        </td>
      </tr>
      <tr>
        <td style={{ fontSize: '2rem' , fontWeight: 'bold', textAlign: 'left'}}>
        Density - UST 2 <br />
        <span style={{fontWeight: 'normal' }}>(Kg/m<sup>3</sup>)</span>
        </td>
        <td
          style={{
            backgroundColor: '#000',
            borderWidth: 10,
            borderColor: '#ffffff',
          }}
        >
          <span className="highlight" id="petroldensity">
            {fuelData.petroldensity_ust2}
          </span>
        </td>
        <td
          style={{
            backgroundColor: '#000',
            borderWidth: 10,
            borderColor: '#ffffff',
          }}
        >
          <span className="highlight" id="dieseldensity">
            {fuelData.dieseldensity_ust2}
          </span>
        </td>
      </tr>
      <tr>
        <td style={{ fontSize: '2rem', fontWeight: 'bold' ,textAlign: 'left' }}>Rate <br />
        <span style={{fontWeight: 'normal' }}>(₹/L)</span>
          </td>
        <td
          style={{
            backgroundColor: '#000',
            borderWidth: 10,
            borderColor: '#ffffff',
          }}
        >
          <span className="highlight" id="petrolrate">
            {fuelData.petrolrate}
          </span>
        </td>
        <td
          style={{
            backgroundColor: '#000',
            borderWidth: 10,
            borderColor: '#ffffff',
          }}
        >
          <span className="highlight" id="dieselrate">
            {fuelData.dieselrate}
          </span>
        </td>
      </tr>
    </tbody>
  </table>

  <footer className="footer">
    <p
      style={{
        backgroundColor: '#10963D',
        padding: '20px 20px 20px 60px',
        color: '#FFFFFF',
        // borderRadius: 10,
        fontWeight:"bold"
      }}
    >
      <span style={{fontWeight: 'bold', color: '#FFFFFF'}}>
        Reliance BP Mobility Limited
      </span>
      <br />
      <span style={{ color: "#ffffff" ,fontWeight:"normal"}}>रिलायंस बीपी मोबिलिटी लिमिटेड</span>
      <br />
      <span style={{ color: '#FFFFFF',fontWeight:"normal" }}>GSTIN: {fuelData.gstnoreliancebp}</span>
      <br />
      <span style={{ fontWeight: 'bold' ,color: '#FFFFFF'}}>RBML Solutions India Limited</span>
      <br />
      <span style={{ color: "#ffffff" ,fontWeight:"normal"}}>आरबीएमएल सोल्युशन्स इंडिया लिमिटेड</span>
      <br />
      <span style={{ color: '#FFFFFF',fontWeight:"normal" }}>GSTIN: 27AAKCR8762R1Z7
      <br />
      PESO Lic. No: {fuelData.pesono} Valid Upto: {fuelData.gstvalidity}<br />
      CNG PESO Lic. No : G/WC/MH/07/670(G125861) Valid Upto: 30.09.2028</span>
    </p>
    <div className="contacts">
      <p>
      In case of Emergency:
      <br />
      <span style={{ fontWeight: 'bold' }}>
        POLICE: 100 | FIRE: 101 | HOSPITAL: 102
      </span><br />
        <span style={{ color: '#000', fontWeight:"normal"}}>
          Name and Contact No. Area Manager:
        </span>
        <br />
        {fuelData.manager.toUpperCase()} - {fuelData.managerconatct}
        <br />
        <span style={{ color: '#000',fontWeight:"normal" }}>
          Name and Contact No. Area Operator:
        </span>
        <br />
        {fuelData.operator.toUpperCase()} - {fuelData.operatorcontact}
      </p>
    </div>
    {/* <p>
      <span style={{ color: '#000000' }}>Internal Complaints Committee</span>
      <span style={{ color: '#2c85e1' }}> 
        <br />
        rbml.diversity@gmail.jiobp.com </span>
        <br />
         Contact: 022-31158910
    </p> */}
    <p
  style={{
    backgroundColor: "#10963D",
    color: "rgb(255, 255, 255)",
    margin: "10px",
    padding: "20px 60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }}
>
  <span>
    For your valuable feedback call 1800-191-9023
    <br />
    or write to us at
    <br />
    customercare.petroleum@jiobp.com
  </span>
  <img
    src="qr.jpeg"
    alt="QR Code"
    style={{ height: "10rem", width: "auto", marginLeft: "20px" }}
  />
</p>
<span className="emergency">RO Code - <b>MHC117</b></span>

  </footer>
</div> </>
  );
};

export default HomePage;
